import React from "react";
import { Routes, Route, useParams, Navigate } from "react-router-dom";
import { default as Links } from "./links";
import { default as Home } from "./home";
import { default as Apply } from "./apply";
import { default as ApplyIntegrator } from "./apply/daikin";
import { default as ApplySunburn } from "./apply/sunburn";
import { default as ApplySunRun } from "./apply/sunrun";
import { default as ApplyBundleLoan } from "./apply/bundle";
import { default as Partners } from "./partners";
import { default as Edit } from "./edit";
import { default as Requests } from "./requests";
import { default as Request } from "./request";
import { default as PublicRequest } from "./request-public";
import { default as Organizations } from "./organizations";
import { default as Export } from "./export";
import { default as Keys } from "./keys"
import { default as NotFoundPage } from "../utils/not-found";

const ApplyIntegratorRoute = () => {
  const { integrator } = useParams();

  // Check if the 'integrator' starts with 'dk-'
  if (integrator.startsWith('dk-')) {
    return <ApplyIntegrator />;
  }

  // Fallback: redirect to Apply component if it doesn't match the pattern
  return <Navigate to={`/apply/${integrator}`} />;
};

const Router = () => {
  return (
    <>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/:client/:id" exact element={<Apply />} />
        <Route path="/dk-:integrator/:client/:id" exact element={<ApplyIntegrator />} />
        <Route path="/:client/:id/:offerId" exact element={<Apply />} />
        <Route path="/apply/:client/:id" exact element={<ApplySunburn />} />
        <Route path="/sunrun/:id" exact element={<ApplySunRun />} />
        <Route path="/:integrator/:client/la/:id" exact element={<Apply />} />
        <Route path="/:integrator/:client/:id" exact element={<ApplyIntegratorRoute />} />
        <Route path="/glp/:client/:bundleClientId/:id" exact element={<ApplyBundleLoan />} />
        <Route path="/edit/:id" element={<Edit />} />
        <Route path="/edit/:action/:id" exact element={<Edit />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/admin" element={<Partners />} />
        <Route path="/links" element={<Links />} />
        <Route path="/requests" element={<Requests />} />
        <Route path="/request/:id" element={<Request />} />
        <Route path="/request-quicklink/:id" exact element={<PublicRequest />} />
        <Route path="/organizations/:id" element={<Organizations />} />
        <Route path="/export" element={<Export />} />
        <Route path="/keys" element={<Keys />}/>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
};

export default Router;
