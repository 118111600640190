export const Categories = {
  SOLAR: {
    cat: "SOLAR",
    label: "Solar",
    name: "SOLAR",
    description: "Includes Solar, Battery and Home Efficiency",
    imageUrl: "/images/SOLAR_1.png"
  },
  HOME_IMPROVEMENT: {
    cat: "HOME_IMPROVEMENT",
    label: "Oher Home Improvement",
    name: "HOME IMPROVEMENT",
    description: "Max loan term is 15-year on Standard installment loan products.",
    imageUrl: "/images/HI_1.png"
  },
  ROOF: {
    cat: "ROOF",
    label: "Roofing / Windows / Doors / Siding / Geothermal / Restoration",
    name: "ROOFING / WINDOWS / DOORS / SIDING / GEOTHERMAL / RESTORATION",
    description: "Max loan term is 20-year on Standard installment loan products.",
    imageUrl: "/images/ROOF_1.png"
  },
  BATTERY: {
    cat: "BATTERY",
    label: "Stand-Alone Battery",
    name: "BATTERY",
    description: "Battery must have an existing solar system to install.",
    imageUrl: "/images/BATTERY_1.png"
  }
};

export const CategoryMinMax = [
    {
        type: "HOME_IMPROVEMENT",
        min: 1000,
        max: 55000
    },
    {
        type: "SOLAR",
        min: 5000,
        max: 135000
    },
    {
        type: "BATTERY",
        min: 5000,
        max: 135000
    },
    {
        type: "ROOF",
        min: 2000,
        max: 100000
    }
];
